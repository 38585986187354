import { CartActions, CartState } from "../types"

/**
 * Useful as a default case in switch statements to ensure exhaustive
 * handling of enums.
 */
export function assertNever(x: never): never {
  throw new Error(`Unexpected value ${x}`)
}
export function reducer(state: CartState, action: CartActions): CartState {
  const { type, payload } = action

  switch(type) {
    case 'sum-item': {
      const { item, quantity } = payload
      const existing = state.items[item.id]
      if (!existing && quantity <= 0) {
        return { ...state }
      }

      if (!existing) {
        return {
          ...state,
          items: {
            ...state.items,
            [item.id]: payload
          }
        }
      }

      const newQty = existing.quantity + quantity

      if (newQty <= 0) {
        const items = { ...state.items }
        delete items[item.id]

        return {
          ...state,
          items
        }
      }

      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: { item, quantity: newQty }
        }
      }
    }
    case 'set-item': {
      const { item, quantity } = payload
      const items = { ...state.items }

      if (quantity <= 0) {
        delete items[item.id]
      } else {
        items[item.id] = payload
      }

      return {
        ...state,
        items
      }
    }

    case 'replace-items':
      return {
        ...state,
        items: payload.reduce((acc, item) => ({
          ...acc,
          [item.item.id]: item
        }), {} as CartState['items'])
      }

    default:
      assertNever(action)
  }
}
