import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@emotion/react";
import { Box, createTheme, CssBaseline } from "@mui/material";
import FilteredCatalog from "./catalog/components/FilteredCatalog";
import CartProvider from "./cart/context/Provider";
import ResponsiveCart from "./cart/components/ResponsiveCart";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline>
        <CartProvider>
          <Box
            sx={{
              height: "calc(100vh + 0px)",
              maxWidth: "100vw",
              overflow: "auto",
              display: "flex",
              pb: { 'xs': '65px', 'sm': 0 },
            }}
          >
            <FilteredCatalog sx={{ flex: "3 0 auto" }} />
            <ResponsiveCart sx={{  flex: "2 1 auto", minWidth: "200px" }} />
          </Box>
        </CartProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
