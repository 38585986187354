export enum ItemTypes {
  "Ammunition" = "Ammunition",
  "Other Adventuring Gear" = "Other Adventuring Gear",
  "Spellcasting Focus" = "Spellcasting Focus",
  "Tools" = "Tools",
}

export enum CURRENCY {
  "gp" = "gp",
  "sp" = "sp",
  "cp" = "cp",
}

export interface Item {
  id: string
  name: string
  cost: number
  currency: CURRENCY
  weight: number
  weightUnit?: string
  type: ItemTypes
  subtype?: string
}

export type GroupedCatalog = Array<{
  type: Item['type'],
  items: Item[]
}>

export interface Filters {
  name?: string
  type?: Item['type'][]
}

export const LoadingSymbol = Symbol('loading')
