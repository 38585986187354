import {Box, Typography} from '@mui/material'
import React, {useContext} from 'react'
import {CartContext} from '../context/Provider'
import CartTotal from './CartTotal'

interface CartSummaryProps { }

const CartSummary:React.FC<CartSummaryProps> = ({ ...props }) => {
  const { state } = useContext(CartContext)
  const items = Object.values(state.items)
  if (!items.length) {
    return <Typography variant="h5">Empty Cart</Typography>
  }

  const count = items.reduce((sum, item) => sum + item.quantity, 0)
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Typography variant="h5">{`${count} items`}</Typography>
      <CartTotal items={items} variant="h6" />
    </Box>
  )
}

export default CartSummary
