import {getByIds} from "../catalog"
import {CURRENCY} from "../catalog/types"
import {CartItem} from "./types"

const currencies: readonly CURRENCY[] = [
  CURRENCY.gp,
  CURRENCY.sp,
  CURRENCY.cp,
] as const

export function toLowestCurrency(amount: number, currency: CURRENCY): number {
  const ix = currencies.indexOf(currency)
  const next = currencies[ix+1]

  if (next) {
    return toLowestCurrency(amount * 10, next)
  }

  return amount
}

export function toHighestCurrency(amount: number, currency: CURRENCY) {
  let ix = currencies.indexOf(currency)

  if (ix === -1) {
    throw new Error('Unknown currency')
  }

  if (!ix) {
    return `${amount}${currency}`
  }

  const ret:[number, string][] = []
  let remainingAmount = amount

  while(ix >= 0 && remainingAmount > 0) {
    const curr = currencies[ix]
    if (ix > 0) {
      const modulo = remainingAmount % 10
      if (modulo) {
        ret.unshift([modulo, curr])
      }
      remainingAmount = Math.floor(remainingAmount / 10)
    } else {
      ret.unshift([remainingAmount, curr])
    }
    ix--
  }


  return ret.map(tuple => tuple.join('')).join(', ')
}

export function serializeItems(items: CartItem[]) {
  return items.map((item) => [item.item.id, item.quantity].join(',')).join(';')
}

export async function deserializeItems(serialized: string): Promise<CartItem[]> {
  const qtyIx = serialized.split(';').reduce((agg, p) => {
    const [id, quantity] = p.split(',')
    if (!id || !quantity) {
      throw new Error(`malformed: ${serialized}`)
    }

    const intQty = parseInt(quantity)

    if (isNaN(intQty)) {
      throw new Error(`malformed: ${serialized}`)
    }

    return {
      ...agg,
      [id]: intQty
    }
  }, {} as { [id: string]: number })

  const items = await getByIds(Object.keys(qtyIx))

  return items.map(item => ({
    item,
    quantity: qtyIx[item.id]
  }))
}
