import { PropsOf } from "@emotion/react";
import { Box, Card, Divider, Typography } from "@mui/material";
import React, { PropsWithChildren, useContext, useEffect } from "react";
import { CartContext } from "../context/Provider";
import CartItems from "./CartItems";
import CartTotal from "./CartTotal";
import { serializeItems } from "../functions";
import { useCartSerializer } from "../hooks/useCartSerializer";
import Loader from "../../ui/Loader";

interface CartProps extends PropsOf<typeof Card> {}

const Cart: React.FC<CartProps> = (props) => {
  const { items, isLoading } = useCartSerializer();
  const isEmpty = !items.length;

  if (isLoading) {
    return (
      <CartContainer {...props}>
        <Loader />
      </CartContainer>
    );
  }

  return (
    <CartContainer {...props}>
      {isEmpty ? (
        <Typography>Start shopping</Typography>
      ) : (
        <>
          <CartItems items={items} />
          <Divider />
          <Box sx={{ mt: 2 }}>
            <CartTotal items={items} />
          </Box>
        </>
      )}
    </CartContainer>
  );
};

interface CartContainerProps extends PropsOf<typeof Card> {}

const CartContainer: React.FC<PropsWithChildren<CartContainerProps>> = ({
  sx,
  children,
  ...props
}) => {
  return (
    <Card sx={{ p: 2, ...sx }} {...props}>
      <Typography variant="h5">Cart</Typography>
      {children}
    </Card>
  );
};

export default Cart;
