import { PropsOf } from "@emotion/react";
import {
  Card,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { ChangeEvent, useState } from "react";
import { Filters, ItemTypes } from "../types";
import Catalog from "./Catalog";

interface FilteredCatalogProps extends PropsOf<typeof Card> {}

const FilteredCatalog: React.FC<FilteredCatalogProps> = ({ sx, ...props }) => {
  const [filters, setFilters] = useState<Filters>({});
  const nameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.currentTarget.value;
    setFilters((current) => ({ ...current, name: value }));
  };

  const typeChange = (ev: SelectChangeEvent<ItemTypes[]>) => {
    const value = ev.target.value as ItemTypes[];
    setFilters((current) => ({ ...current, type: value }));
  };

  return (
    <Card
      sx={{
        p: 2,
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        ...sx,
      }}
      {...props}
    >
      <Paper elevation={2} sx={{ mb: 4, flex: "0 0 auto" }}>
        <TextField
          label="Name(ish)"
          variant="standard"
          onChange={nameChange}
          value={filters.name || ""}
          sx={{ m: 2, flex: '1 1 50%', minWidth: '7em' }}
        />
        <FormControl variant="standard" sx={{ minWidth: "7em", m: 2, flex: '1 1 50%' }}>
          <InputLabel>Item Type</InputLabel>
          <Select
            label="Item Type"
            multiple
            value={filters.type || []}
            onChange={typeChange}
            autoWidth
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {Object.values(ItemTypes).map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
      <Paper elevation={2} sx={{ flex: "1 1 100%", overflow: "auto" }}>
        <Catalog filters={filters} />
      </Paper>
    </Card>
  );
};

export default FilteredCatalog;
