import {useContext, useEffect, useRef, useState} from "react"
import {CartContext} from "../context/Provider"
import {serializeItems, deserializeItems} from "../functions"

export function useCartSerializer() {
  const { state, dispatch } = useContext(CartContext)
  const items = Object.values(state.items)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const url = new URL(document.location.toString())
    const serialized = url.searchParams.get('c')
    if (!serialized) {
      setIsLoading(false)
      setIsMounted(true)
      return
    }

    deserializeItems(serialized).then(items => {
      dispatch({
        type: 'replace-items',
        payload: items
      })
      setIsLoading(false)
      setIsMounted(true)
    })
  }, [])

  useEffect(() => {
    if (!isMounted) {
      return
    }
    const serialized = serializeItems(items)
    const url = new URL(document.location.toString())

    if (serialized) {
      url.searchParams.set('c', serialized)
    } else {

      url.searchParams.delete('c')
    }

    window.history.replaceState(undefined, '', url)
  }, [items, isMounted])

  return { items, isLoading }
}
