import {List, ListItem} from '@mui/material'
import React from 'react'
import AddToCart from '../../catalog/components/AddToCart'
import ItemSnippet from '../../catalog/components/ItemSnippet'
import {CartItem} from '../types'

interface CartItemsProps {
  items: CartItem[]
}

const CartItems:React.FC<CartItemsProps> = ({ items }) => {
  return (
    <List sx={{ '& li': {p: 0, my: 1}}}>{items.map(({item}) => (
      <ListItem
        key={item.id}
        secondaryAction={<AddToCart item={item} />}
      >
        <ItemSnippet item={item}/>
      </ListItem>
    ))} </List>
  )
}

export default CartItems
