import { ListItemText } from "@mui/material";
import React from "react";
import { Item } from "../types";

interface ItemSnippetProps {
  item: Item;
}

const ItemSnippet: React.FC<ItemSnippetProps> = ({ item }) => {
  return (
    <ListItemText
      primary={item.name}
      secondary={`${item.cost}${item.currency}${
        item.weight ? ` - ${item.weight}${item.weightUnit}` : ''
      }`}
    />
  );
};

export default ItemSnippet;
