import React, {PropsWithChildren, useReducer, createContext, Dispatch} from 'react'
import {CartActions, CartState} from '../types'
import {reducer} from './reducer'

const getInitialState = (): CartState => ({
  items: {}
})

export const CartContext = createContext<{
  state: CartState,
  dispatch: Dispatch<CartActions>
}>({
  state: getInitialState(),
  dispatch: () => { throw new Error ('uninitialized') },
})

interface CartProviderProps { }

const CartProvider:React.FC<PropsWithChildren<CartProviderProps>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState())

  return (
    <CartContext.Provider
      value={{state, dispatch}}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider
