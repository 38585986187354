import { PropsOf } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import { CURRENCY } from "../../catalog/types";
import { toHighestCurrency, toLowestCurrency } from "../functions";
import { CartItem } from "../types";

interface CartTotalProps extends PropsOf<typeof Typography> {
  items: CartItem[];
}

const CartTotal: React.FC<CartTotalProps> = ({ items, ...props }) => {
  const total = items.reduce((sum, { quantity, item: { cost, currency } }) => {
    return sum + toLowestCurrency(quantity * cost, currency);
  }, 0);

  return (
    <Typography {...props}>
      Total: {toHighestCurrency(total, CURRENCY.cp)}
    </Typography>
  );
};

export default CartTotal;
