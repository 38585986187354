import { Filters, GroupedCatalog, Item, ItemTypes } from './types'
import catalog from './data/table.json'



function getCatalog(): Item[] {
  return catalog as Item[]
}

function groupItems(items: Item[]): GroupedCatalog {
  const ix = items.reduce((acc, item) => {
    if (!acc[item.type]) {
      acc[item.type] = { items: [], type: item.type }
    }

    acc[item.type].items.push(item)

    return acc
  }, {} as {[key in ItemTypes]: GroupedCatalog[number]})

  return Object.values(ix)
}

export async function search({ name, type }: Filters): Promise<GroupedCatalog> {
  const catalog = getCatalog()

  if (!name && !type?.length) {
    return groupItems(catalog)
  }

  return groupItems(catalog.filter(item => {
    if (type?.length && !type.includes(item.type)) {
      return false
    }

    if (name && item.name.toLowerCase().indexOf(name.toLowerCase()) === -1) {
      return false
    }

    return true
  }))
}

export async function getByIds(ids: string[]): Promise<Item[]> {
  const catalog = getCatalog()

  return catalog.filter(item => ids.includes(item.id))
}
