import React from "react";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { useCatalog } from "../hooks/useCatalog";
import { Filters, LoadingSymbol } from "../types";
import Loader from "../../ui/Loader";
import { ListItemText, ListSubheader } from "@mui/material";
import AddToCart from "./AddToCart";
import ItemSnippet from "./ItemSnippet";

interface CatalogProps {
  filters?: Filters;
}

const Catalog: React.FC<CatalogProps> = ({ filters }) => {
  const data = useCatalog(filters);

  if (data === LoadingSymbol) {
    return <Loader title="Loading" />;
  }
  return (
    <List subheader={<li />} sx={{ '& ul': { p: 0 }}}>
      {data.map((cat) => (
        <li key={cat.type}>
          <ListSubheader>{cat.type}</ListSubheader>
          <ul>
            {cat.items.map((item) => (
              <ListItem
                key={item.id}
                secondaryAction={<AddToCart item={item} />}
              >
                <ItemSnippet item={item} />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

export default Catalog;
