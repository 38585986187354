import { Global, PropsOf } from "@emotion/react";
import {
  Box,
  Card,
  Paper,
  styled,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Cart from "./Cart";
import CartSummary from "./CartSummary";

interface ResponsiveCartProps extends PropsOf<typeof Card> {}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor:  theme.palette.grey[800],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const drawerBleeding = 64;
const ResponsiveCart: React.FC<ResponsiveCartProps> = ({ ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 1);
    }, 250);
  }, []);

  if (!isMobile) {
    return <Cart {...props} />;
  }
  return (
    <>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(60% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        ModalProps={{ keepMounted: true }}
        disableSwipeToOpen={false}
        swipeAreaWidth={drawerBleeding}
      >
          <Paper
            sx={{
              position: "absolute",
              top: -drawerBleeding + 1,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
              minHeight: `${drawerBleeding}px`,
              p: 2,
              boxShadow: '0',
              mx: 1,
            }}
          >
            <Puller />
            <CartSummary />
          </Paper>
        <Box sx={{ mx: 1, overflow: 'auto' }}>
          <Cart {...props} />
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default ResponsiveCart;
