import { CircularProgress, Paper, Typography } from '@mui/material'
import React from 'react'

interface LoaderProps {
  title?: string
  label?: string
}

const Loader:React.FC<LoaderProps> = ({ title, label }) => {
  return (
    <Paper sx={{
      margin: 2,
      padding: 4,
      textAlign: 'center',
    }}>
      <CircularProgress />
      { title && (
        <Typography variant="h2" component="p">
          {title}
        </Typography>
      )}
      { label && (
        <Typography component="p">
          {label}
        </Typography>
      )}
    </Paper>
  )
}

export default Loader
