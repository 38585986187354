import {
  Button,
  ButtonGroup,
  OutlinedInput,
} from "@mui/material";
import React, { ChangeEvent, useContext } from "react";
import { CartContext } from "../../cart/context/Provider";
import { Item } from "../types";

interface AddToCartProps {
  item: Item;
}

const AddToCart: React.FC<AddToCartProps> = ({ item }) => {
  const {
    state: { items },
    dispatch,
  } = useContext(CartContext);

  const cartAdder = (quantity: number) => () => {
    dispatch({
      type: "sum-item",
      payload: {
        item,
        quantity,
      },
    });
  };

  const qtyUpdated = (ev: ChangeEvent<HTMLInputElement>) => {
    const quantity = parseInt(ev.target.value);
    if (isNaN(quantity)) {
      return;
    }

    dispatch({
      type: "set-item",
      payload: {
        item,
        quantity,
      },
    });
  };

  if (!items[item.id]) {
    return <Button onClick={cartAdder(1)}>Add to cart</Button>;
  }

  return (
    <ButtonGroup>
      <Button onClick={cartAdder(-1)}>-</Button>
      <OutlinedInput
        type="text"
        value={items[item.id].quantity}
        onChange={qtyUpdated}
        sx={{
          textAlign: 'center',
          width: '3.5em',
        }}
      />
      <Button onClick={cartAdder(1)}>+</Button>
    </ButtonGroup>
  );
};

export default AddToCart;
