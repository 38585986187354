import { useEffect, useState } from "react"
import {search} from ".."
import { Filters, GroupedCatalog, Item, LoadingSymbol } from "../types"

export function useCatalog({
  name, type
}: Filters = {}) {
  const [data, setData] = useState<GroupedCatalog | typeof LoadingSymbol>(LoadingSymbol)

  useEffect(() => {
    search({ name, type }).then((result) => {
      setData(result)
    })
  }, [name, type])

  return data
}
